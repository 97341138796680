import React from "react";
import { Link, useLocation } from "react-router-dom";

const SideMenu = ({ isOpen, toggleMenu }) => {
  const location = useLocation().pathname;
  return (
    <div
      className={`${
        !isOpen ? "hidden" : ""
      } w-full bg-white backdrop-blur-sm h-full fixed top-0 left-0 z-10  `}
    >
      {/* Side menu */}
      <nav className=" text-gray-900 w-full transform transition-transform duration-300 ease-in-out">
        <button
          className="absolute top-7 right-7   rounded-full w-8 h-8 flex items-center justify-center text-white md:hidden"
          onClick={toggleMenu}
        >
          <i className="fi fi-br-cross"></i>
        </button>

        <ul className="py-32 p-5">
          <div className="text-3xl mb-2">Navigation</div>
          <li className="border-b border-gray-200  text-xl">
            <Link
              onClick={toggleMenu}
              to="/"
              className={`hover:text-gray-400 py-3 block ${
                location === "/" ? "text-red-500 font-bold" : ""
              }`}
            >
              Home
            </Link>
          </li>
          <li className="border-b border-gray-200  text-xl">
            <Link
              onClick={toggleMenu}
              to="/about"
              className={`hover:text-gray-400 py-3 block ${
                location === "/about" ? "text-red-500 font-bold" : ""
              }`}
            >
              About
            </Link>
          </li>
          <li className="border-b border-gray-200  text-xl">
            <Link
              onClick={toggleMenu}
              to="/Products"
              className={`hover:text-gray-400 py-3 block ${
                location === "/Products" ? "text-red-500 font-bold" : ""
              }`}
            >
              Products
            </Link>
          </li>
          <li className="border-b border-gray-200 text-xl ">
            <Link
              onClick={toggleMenu}
              to="/Contact"
              className={`hover:text-gray-400 py-3 block ${
                location === "/Contact" ? "text-red-500 font-bold" : ""
              }`}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SideMenu;
