import { Link } from "react-router-dom";
import Container from "../components/Container/Container";
import bannerImageOne from "../assets/about-image-1.png";
import bannerImageTwo from "../assets/about-image-2.png";
import p1 from "../assets/p1.png";
import commitmentImage from "../assets/commitment.png";
import quoteIcon from "../assets/quoteicon.png";
import mainBannerImage from "../assets/banner-image.png";
import mobileBanner from "../assets/mobile-banner.png";
import arrow from "../assets/arrow.png";
import arrowClr from "../assets/clr-arrow.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTab } from "../context/TabContext";
import buildingMaterialImage from "../assets/building-materials.png";
import aluminiumProductImage from "../assets/aluminium-products.png";
import steelCastingImage from "../assets/steel-casting-for-crusher-segment.png";
import supplyOfCrusherImage from "../assets/supply-of-crusher-products.png";
import bannerShape from "../assets/shape-banner.png";

import transformerEncloures from "../assets/products/transformerEnclosure.jpg";
import wallMount from "../assets/products/wallMount.jpg";
import batterCabnet from "../assets/products/batterycabinet.jpg";
import batteryRacks from "../assets/products/batteryracks.jpg";
import controldesk from "../assets/products/controldesk.jpg";
import { useEffect, useState } from "react";
import bannerVideo from "../assets/video.mp4";

function Testimonials() {
  var settings = {
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    prevArrow: null,
    nextArrow: null,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <div className="px-3 p-2 rounded-md">
        <img src={quoteIcon} className="w-ful" alt="quote icon" />
        <p className="italic">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
          hendrerit, ipsum quis adipiscing elit efficitur egesta Sed hendrerit,
          ipsum quis
        </p>
        <p>John Williams</p>
        <span>Manager | MT Groups</span>
      </div>
      <div className="px-3">
        <img src={quoteIcon} className="w-ful" alt="quote icon" />
        <p className="italic">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
          hendrerit, ipsum quis adipiscing elit efficitur egesta Sed hendrerit,
          ipsum quis
        </p>
        <p>John Williams</p>
        <span>Manager | MT Groups</span>
      </div>
      <div className="px-3">
        <img src={quoteIcon} className="w-ful" alt="quote icon" />
        <p className="italic">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
          hendrerit, ipsum quis adipiscing elit efficitur egesta Sed hendrerit,
          ipsum quis
        </p>
        <p>John Williams</p>
        <span>Manager | MT Groups</span>
      </div>
    </Slider>
  );
}

const Home = () => {
  const [bannerImage, setBannerImage] = useState(mainBannerImage);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setBannerImage(mobileBanner); // Set mobile image
      } else {
        setBannerImage(mainBannerImage); // Set desktop image
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check the initial window size on load

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const bannerStyle = {
    backgroundImage: `url(${bannerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const { activeTab, setActiveTab } = useTab();

  const tabHandler = ({ id }) => {
    setActiveTab(id);
  };
  return (
    <>
      {/* hero */}
      <section className="h-[80svh] md:h-[100svh] w-full bg-black text-white flex items-center justify-center relative">
        <video
          src={bannerVideo}
          autoPlay={true}
          muted={true}
          loop={true}
          className="absolute top-0 left-0 w-full h-full object-cover"
        ></video>
        <div className="absolute z-20 right-0 bottom-0 w-full md:hidden">
          <img src={bannerShape} className="w-full" />
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-black/60 z-1"></div>
        <Container className="relative z-10">
          <div className="content lg:w-[60%] flex items-start flex-col justify-center ">
            <h1 className="text-4xl md:text-6xl mb-5 font-bold">
              Trusted Enclosure Manufacture in Sharjah, UAE
            </h1>
            <p className="md:text-xl">
              we specialize in high-quality electrical enclosures designed for
              safety and reliability. Our solutions meet the strictest industry
              standards, ensuring durability and performance for various
              industries
            </p>
            <Link
              to="/products"
              className="mt-4 flex items-center bg-red-600 rounded-full justify-between  px-2 text-white py-2"
            >
              View Products <img className="w-8 ml-3" src={arrow} />
            </Link>
          </div>
        </Container>
      </section>

      <Container>
        {/* about */}
        <section className=" pb-10 md:py-10 ">
          <div className="md:flex lg:flex">
            <div className="md:w-1/2 lg:w-1/2 pr-10">
              <div className="text-[15px] mb-2  text-red-600 font-medium">
                ABOUT
              </div>
              <h2 className="font-semibold text-2xl mb-8 lg:mb-0">
                Since our inception in 2010. Focus International has been atthe
                forefront of sheet metal enclosure manufacturing in UAE.
              </h2>
            </div>
            <div className="md:w-1/2 lg:w-1/2">
              <p>
                With over a decade of industry experience, we have established
                ourselves as a reliable and innovative provider of a diverse
                range of enclosure solutions. Our commitment to quality,
                precision, and customer satisfaction has propelled us to become
                a preferred choice for clients not only in the UAE but also
                across the GCC and African countries.
              </p>

              <Link to="/about">
                <div className="mt-8 ">
                  <div className="py-2 pl-1 w-[150px] flex font-medium text-md  items-center justify-center border-2 border-red-500 rounded-full text-red-500 hover:bg-red-500 hover:text-white">
                    Read More{" "}
                    <img className="w-8 ml-5 rounded-full" src={arrowClr} />
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className=" mt-10 flex-col md:flex-row hidden md:flex">
            <div className="w-full md:w-[68%] pr-10">
              <img src={bannerImageOne} alt="about-image" className="w-full" />
            </div>
            <div className="w-full md:w-[33%]">
              <img src={bannerImageTwo} alt="about-image" className="w-full" />
            </div>
          </div>
        </section>
        {/* products */}
        <div>
          <div className="flex py-10">
            <div className="lg:w-1/2 pr-10">
              <div className="text-[15px] mb-2 text-red-600 font-medium">
                OUR NEW PRODUCTS
              </div>
              <h2 className="font-semibold text-2xl mb-5">
                Comprehensive Building Materials Supply with Unmatched Precision
                and Excellence
              </h2>
              <Link
                to="/products"
                className="py-2 pl-1 w-[150px] flex font-medium text-md  items-center justify-center border-2 border-red-500 rounded-full text-red-500 hover:bg-red-500 hover:text-white"
              >
                View All{" "}
                <img className="w-8 ml-5 rounded-full" src={arrowClr} />
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3">
            {[
              {
                id: 1,
                title: "Wall Mount Enclosures",
                source: wallMount,
              },
              {
                id: 4,
                title: "Battery Cabnet",
                source: batterCabnet,
              },
              {
                id: 3,
                title: "Transformer Enclosures",
                source: transformerEncloures,
              },
              {
                id: 5,
                title: "Battery Racks",
                source: batteryRacks,
              },
            ].map((item) => (
              <Link
                to={`/product/${item.id}`}
                className="rounded-xl overflow-hidden relative after:contents['']  "
                key={item.id}
              >
                <div className="bg-gradient-to-t from-black to-transparent absolute left-0 top-0 w-full h-full z-0"></div>
                <img src={item.source} alt="about-image" className="w-full" />
                <div className="absolute left-0 bottom-0 p-5 z-1 text-white">
                  <span className="uppercase w-8 h-8 flex items-center justify-center bg-red-600 rounded-full">
                    0{item.id}
                  </span>
                  <h3 className="text-xl">{item.title}</h3>
                </div>
              </Link>
            ))}
          </div>
        </div>
        {/* commitments */}
        <div className="mt-28">
          <div className="lg:flex py-10">
            <div className="lg:w-1/2 pr-10">
              <div className="text-[15px] mb-2 text-red-600 font-medium">
                WHY US
              </div>
              <h2 className="font-semibold text-2xl">
                Why We’re Your Trusted Enclosure Partner
              </h2>
            </div>
          </div>
          <div className="lg:flex">
            <div className="w-full lg:w-1/2">
              <img className="w-full" src={commitmentImage} alt="about-image" />
            </div>
            <div className="mt-8 lg:w-1/2 lg:px-10 flex items-center">
              <ul>
                {[
                  {
                    id: 1,
                    title: "Unmatched Quality",
                    description:
                      "We manufacture enclosures using premium materials, ensuring safety, durability, and compliance with industry standards.",
                  },
                  {
                    id: 2,
                    title: "Custom Solutions",
                    description:
                      "We offer a wide range of standard and custom-built enclosures to meet your specific requirements..",
                  },
                  {
                    id: 3,
                    title: "Competitive Pricing",
                    description:
                      "Our products provide excellent value without compromising on quality, helping you stay within budget.",
                  },
                  {
                    id: 4,
                    title: "On-Time Delivery",
                    description:
                      "We prioritize timely delivery to ensure your projects stay on schedule.",
                  },
                ].map((item) => (
                  <li className="flex justify-between mb-10" key={item.id}>
                    <div className="font-semibold bg-red-200 h-10 w-10 rounded-full flex items-center justify-center ">
                      0{item.id}
                    </div>
                    <div style={{ width: "calc(100% - 60px)" }}>
                      <h3 className="text-xl font-semibold mb-2">
                        {item.title}
                      </h3>
                      <p>{item.description}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>

      {/* testimonials */}
      <div className="bg-[#110000] text-white py-10 lg:py-32 mt-20">
        <Container>
          <div>
            <div className="lg:w-1/2 pr-10 mb-10">
              <div className="text-[15px] mb-2 text-red-600 font-medium">
                TESTIMONIALS
              </div>
              <h2 className="font-semibold text-2xl">What Our Clients Say</h2>
            </div>
            <Testimonials />
          </div>
        </Container>
      </div>
    </>
  );
};
export default Home;
