import "./App.css";
import AppRouter from "./routes/router";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import favIcon from "./assets/favicon.png";

function App() {
  return (
    <div className="App">
      <FloatingWhatsApp
        phoneNumber="+971508996909"
        accountName="Focus International"
        onSubmit={() => console.log("Submitted")}
        avatar={null}
        darkMode={false}
      />
      <AppRouter />
    </div>
  );
}

export default App;
