import { Link } from "react-router-dom";
import Container from "../Container/Container";
import facebookIcon from "../../assets/facebook.png";
import twitterIcon from "../../assets/twitter.png";
import instagramIcon from "../../assets/instagram.png";
import logo from "../../assets/LOGO.png";
import dubai from "../../assets/dubai.avif";

const Li = ({ children }) => {
  return <li className="text-sm mb-1 text-white">{children}</li>;
};
const Footer = () => {
  return (
    <footer className="bg-gray-white py-12 text-white relative">
      <div className="absolute top-0 left-0 w-full h-full bg-black/60 z-[10]"></div>
      <div className="w-full h-full top-0 left-0 absolute z-[2]">
        <img src={dubai} className="w-full h-full object-cover object-center" />
      </div>
      <Container className="relative z-10">
        <div className="grid grid-cols-1 gap-0 gap-y-10 md:grid-cols-2 lg:grid-cols-5 md:gap-2">
          <div>
            <Link to="/" className="logo text-white">
              {/* <div className="font-semibold text-xl mb-1">
                Seven Elements Trading LLC
              </div>
              <div className="text-[12px]"> STEP IN - TRADE SMART</div> */}
              <img src={logo} className="rounded-xl" alt="logo" width={200} />
            </Link>
          </div>
          <div>
            <h3 className="text-xl mb-3 text-white">Quick Link</h3>
            <ul>
              <Li>
                <Link to="/">Home</Link>
              </Li>
              <Li>
                <Link to="/about">About</Link>
              </Li>
              <Li>
                <Link to="/products">Products</Link>
              </Li>
              <Li>
                <Link to="/contact">Contact</Link>
              </Li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl mb-3">Quick Link</h3>
            <ul>
              <li>
                <Link to="/product/1">Wall Mounting Enclosures</Link>
              </li>
              <li>
                <Link to="/product/2">Form4 Extendable Enclosure</Link>
              </li>
              <li>
                <Link to="/product/3">Transformer Enclosures</Link>
              </li>
              <li>
                <Link to="/product/4">Battery Cabinet</Link>
              </li>
              <li>
                <Link to="/product/5">Battery Rack</Link>
              </li>
              <li>
                <Link to="/product/6">Control Desk Enclosure</Link>
              </li>
              <li>
                <Link to="/products">More</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl mb-3">Contact</h3>
            <p className="text-sm mb-1 text-white">
              Industrial Area-13, Sharjah UAE
            </p>
            <p>
              <p className="text-sm mb-1 text-white">
                <a href="mailto:mails@focusinternationals.com">
                  mails@focusinternationals.com
                </a>
              </p>
              <p className="text-sm mb-1 text-white">
                <a href="tel:+97165485423">+971 6 548 5423</a>
              </p>
            </p>
          </div>
          <div>
            <h3 className="text-xl mb-3">Connect Us</h3>
            <ul className="flex">
              <Li>
                <a href="https://www.facebook.com/share/mLs16mh6fSHGUiha/?mibextid=LQQJ4d">
                  <img
                    className="w-5 mr-3"
                    src={facebookIcon}
                    alt="facebook-icon"
                  />
                </a>
              </Li>
              <Li>
                <a href="#">
                  <img
                    className="w-5 mr-3"
                    src={instagramIcon}
                    alt="instagram-icon"
                  />
                </a>
              </Li>
              <Li>
                <a href="#">
                  <img className="w-5 mr-3" src={twitterIcon} alt="x-icon" />
                </a>
              </Li>
            </ul>
          </div>
        </div>
        <div className="md:text-center py-8 text-sm text-white">
          {" "}
          2024 All rights reserved. Copyright Focus International Designed by{" "}
          <a
            href="https://webkyat.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-purple-500 cursor-pointer"
          >
            Webkyat
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
