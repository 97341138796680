import { useTab } from "../../context/TabContext";

const Tabs = ({ data }) => {
  const { activeTab, setActiveTab } = useTab(data[0].id);
  return (
    <div className="lg:flex w-full lg:justify-between">
      {/* Tab Navigation */}
      <div className="lg:w-[20%] bg-white  rounded-md p-4">
        <h2 className="text-xl mb-5 font-semibold">Categories</h2>
        {data.map(({ id, buttonContent }) => (
          <button
            key={id}
            className={`w-full rounded-lg py-2 border-b-2 px-2  hover:bg-red-600 hover:text-white ${
              id === activeTab ? "bg-red-600 text-white" : ""
            } transition-colors duration-300 text-left`}
            onClick={() => setActiveTab(id)}
          >
            {buttonContent}
          </button>
        ))}
      </div>
      {/* Tab Body */}
      <div className="mt-10 lg:mt-0 lg:w-[78%]  p-2 rounded-md">
        {data.map(({ id, body }) => (
          <div
            key={id}
            className={`${
              activeTab === id ? "grid" : "hidden"
            } grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3`}
          >
            {body}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
