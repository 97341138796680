import Container from "../components/Container/Container";
import Tabs from "../components/Tabs/Tabs";
import transformerEncloures from "../assets/products/transformerEnclosure.jpg";
import wallMount from "../assets/products/wallMount.jpg";
import batterCabnet from "../assets/products/batterycabinet.jpg";
import batteryRacks from "../assets/products/batteryracks.jpg";
import controldesk from "../assets/products/controldesk.jpg";
import speakerbox from "../assets/products/speakerandboxes.jpg";
import form2extension from "../assets/products/form2extentable.jpg";
import form4extension from "../assets/products/form4.jpg";
import monoblock from "../assets/products/monoblock.jpg";
import stainlessSteelEn from "../assets/products/stainless.jpg";
import aluminiumEn from "../assets/products/alumiumenclosure.jpg";
import floorStanding from "../assets/products/floor-standing.jpg";
import packageSubstation from "../assets/products/pakcage-substation.jpg";
import serverRack from "../assets/products/servercabnet.jpg";
import wallrack from "../assets/products/wallrackcabnet.jpg";
import distribution from "../assets/products/distributionboards.jpeg";
import pushButton from "../assets/products/pushbutton-enclosure.jpg";
// import sloped from "../assets/slo";
import { Link } from "react-router-dom";

const Product = () => {
  const Card = (prop) => {
    return (
      <Link
        to={`/product/${prop.id}`}
        className="bg-white rounded-md  mb-5 group"
      >
        <div className="w-full lg:h-[250px] flex items-center justify-center p-2 ">
          <img
            className="lg:w-[100%] rounded-md group-hover:transform group-hover:scale-105 duration-300"
            src={prop.thumbnail}
            alt="{prop.title}"
          />
        </div>
        <div className="p-3">
          <h2 className="mb-3">{prop.title}</h2>
          <span className="text-red-500 p-1 px-3 border-[1px] border-red-500 rounded-xl group-hover:bg-red-500 group-hover:text-white">
            Explore
          </span>
        </div>
      </Link>
    );
  };
  const data = [
    {
      id: 1,
      buttonContent: "Enclousures",
      body: (
        <>
          <Card id={1} title="Wall Mounting Enclosures" thumbnail={wallMount} />
          <Card
            id={2}
            title="Form4 Exentable Enclosure"
            thumbnail={form4extension}
          />
          <Card
            id={3}
            title="Transformer Enclosures"
            thumbnail={transformerEncloures}
          />
          <Card id={4} title="Battery Cabnet" thumbnail={batterCabnet} />
          <Card id={5} title="Battery Racks" thumbnail={batteryRacks} />
          <Card id={6} title="Control Desk Enclosure" thumbnail={controldesk} />
          <Card id={7} title="Speakers and Boxes" thumbnail={speakerbox} />
          <Card
            id={8}
            title="Form 2 Extentable Enclosure"
            thumbnail={form2extension}
          />
          <Card id={9} title="Monoblock Enclosure" thumbnail={monoblock} />
          <Card
            id={10}
            title="Stainless Stell Enclosure"
            thumbnail={stainlessSteelEn}
          />
          <Card
            id={11}
            title="Aluminum Single or Double Wall Enclosure"
            thumbnail={aluminiumEn}
          />
          <Card
            id={12}
            title="Floor Standing Enclosure"
            thumbnail={floorStanding}
          />
          <Card
            id={13}
            title="Package Substations"
            thumbnail={packageSubstation}
          />
          <Card
            id={14}
            title="Server Rack and Cabinet"
            thumbnail={serverRack}
          />
          <Card id={15} title="Wall Rack Cabinet" thumbnail={wallrack} />
          <Card id={16} title="Distribution Boards" thumbnail={distribution} />
          <Card id={17} title="Push Button Enclosure" thumbnail={pushButton} />
          {/* <Card id={18} title="Sloped Top Enclosure" thumbnail={sloped} /> */}
        </>
      ),
    },
    {
      id: 2,
      buttonContent: "Other Products",
      body: null,
    },
  ];

  return (
    <>
      <Container>
        <div className="w-full pr-10 mt-24  lg:mt-32  mb-10">
          <div className="text-[15px] mb-2 text-red-600 font-medium ">
            PRODUCTS
          </div>
          <h1 className="text-2xl font-semibold lg:w-1/2">
            Advanced Electrical Enclosure Solutions: Engineered for Maximum
            Protection and Longevity
          </h1>
        </div>
        <section className="flex">
          <Tabs data={data} />
        </section>
      </Container>
    </>
  );
};

export default Product;
