import aboutImageOne from "../assets/ab1.png";
import assuranceIcon from "../assets/assurance-icon.png";
import competitionIcon from "../assets/competition-icon.png";
import reliable from "../assets/reliable.png";
import Container from "../components/Container/Container";
import aboutCoverImage from "../assets/about-cover.png";
import aboutCoverResImage from "../assets/abt-cover-res.jpg";
// import visionMissionBg from "../assets/vision-mission-bg.png";
import missionIcon from "../assets/mission-icon.png";
import visionIcon from "../assets/vision-icon.png";
import teamOne from "../assets/team1.png";
import teamTwo from "../assets/team2.png";
import indiaFlag from "../assets/india-flag.png";
import aboutBanner from "../assets/about-banner.png";
import iso from "../assets/iso.jpg";

const About = () => {
  return (
    <>
      <div className="cover mt-20">
        <img className="w-full" src={aboutBanner} />
      </div>
      <Container>
        {/* firt section */}
        <section className="mb-20 lg:mb-32   lg:mt-32">
          <div className="w-full flex flex-col  lg:flex-row mt-10">
            <div className="w-full lg:w-1/2 lg:mr-10 order-2 lg:order-1 mt-10 lg:mt-0">
              <img src={iso} alt="materials" className="w-full" />
            </div>
            <div className="mt-8 lg:mt-0 lg:w-1/2 flex flex-col justify-center order-1 lg:order-2">
              <div className="w-full pr-10">
                <div className="text-[15px] mb-2 text-red-600 font-medium">
                  ABOUT
                </div>
                <h1 className="text-2xl font-semibold mb-5">
                  Since our inception in 2010. Focus International has been
                  atthe forefront of sheet metal enclosure manufacturing in UAE.
                </h1>
              </div>
              <p>
                With over a decade of industry experience, we have established
                ourselves as a reliable and innovative provider of a diverse
                range of enclosure solutions. Our commitment to quality,
                precision, and customer satisfaction has propelled us to become
                a preferred choice for clients not only in the UAE but also
                across the GCC and African countries.
              </p>
            </div>
          </div>
        </section>

        {/* about cover */}
        <div className="mt-10">
          <img
            className="w-full hidden lg:block"
            src={aboutCoverImage}
            alt="cover "
          />
          <img
            className="w-full hidden"
            src={aboutCoverResImage}
            alt="cover "
          />
        </div>

        {/* vision mission */}
        <div className="lg:flex gap-5 lg:mt-32">
          <div className="p-6 mb-6 lg:mb-0 lg:p-10 bg-[#F6F6F6] rounded-xl">
            <div className="flex items-center">
              <div className="mr-2 mb-5">
                <img src={missionIcon} alt="mission" className="w-5" />
              </div>
              <div className="text-[15px] mb-2 font-normal">MISSION</div>
            </div>
            <h2 className="text-xl font-semibold mb-5">
              Exceeding Expectations, Delivering Excellence
            </h2>
            <p>
              Focus Internationalis to be the leading provider of innovative and
              high-quality enclosure solutions. We strive to exceed customer
              expectations by delivering cutting-edge designs
            </p>
          </div>
          <div className="p-10 bg-[#F6F6F6] rounded-md">
            <div className="flex items-center">
              <div className="mr-2 mb-5">
                <img src={visionIcon} alt="vision" className="w-5" />
              </div>
              <div className="text-[15px] mb-2 font-normal">MISSION</div>
            </div>
            <h2 className="text-xl font-semibold mb-5">
              Shaping Industries, Empowering Organizations
            </h2>
            <p>
              is to be recognized as the preferred partner for enclosure needs,
              serving diverse industries with tailored solutions that enhance
              equipment performance, protection, and aesthetics. We envision a
              future where our enclosures play a vital role in shaping
              Industries and empowering organizations worldwide
            </p>
          </div>
        </div>

        {/* why choose us */}
        <div className="bg-[#F6F6F6] p-6 lg:p-10 mt-10 hidden">
          <h2 className="text-2xl font-semibold">Why Choose Us?</h2>
          <div className="grid lg:grid-cols-3 gap-5 e py-5">
            {[
              {
                id: 1,
                icon: assuranceIcon,
                title: "Quality Assurance",
                description:
                  "We source our materials from trusted suppliers, ensuring that every product meets industry standards for quality and safety.",
              },
              {
                id: 2,
                icon: competitionIcon,
                title: "Competitive Pricing",
                description:
                  "We offer competitive prices on all our products, providing value without compromising on quality.",
              },
              {
                id: 3,
                icon: reliable,
                title: "Reliable Supply Chain",
                description:
                  "With a robust logistics network, we ensure timely delivery of materials to your construction site, keeping your projects on schedule.",
              },
            ].map((item) => {
              const { id, icon, title, description } = item;
              return (
                <div key={id} className="rounded-md bg-white p-5">
                  <img
                    src={icon}
                    alt={`icon of ` + title}
                    className="w-10 mb-3"
                  />
                  <h3 className="mb-2 text-xl">{title}</h3>
                  <p>{description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </>
  );
};

export default About;
