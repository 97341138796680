import Slider from "react-slick";
import Container from "../components/Container/Container";
import { Link, useParams } from "react-router-dom";
import getData from "./productData";

const Slides = ({ slides }) => {
    const settings = {
        arrow: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        prevArrow: null,
        nextArrow: null,
        centerMode: true,
        centerPadding: "20px",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <Slider {...settings}>
            {slides.map((slide) => (
                <div key={slide.id} className="px-3 ">
                    <img className="w-full rounded" src={slide.image} alt={slide.title} />
                </div>
            ))}
        </Slider>
    );
};

const List = ({ title, value }) => {
    return (
        <li className="flex gap-2 border-b py-2 items-center">
            <span>
                <i className="fi fi-rr-angle-circle-right text-red-500"></i>
            </span>
            <span className="font-normal">{title} :</span>
            <span className="font-semibold">{value}</span>
        </li>
    );
};

const ProductDetails = () => {
    const { productId } = useParams();
    const productData = getData(productId);
    return (
        <Container>
            <section className="mt-20 lg:mt-40 mb-20">
                <div>
                    <Slides slides={productData.slides} />
                    <div className="mt-10">
                        <h1 className="text-2xl mb-4 font-bold">{productData.title}</h1>
                        <Link
                            to="/contact"
                            className="mt-4 w-[120px] font-bold flex items-center bg-red-600 rounded-full justify-center   text-white p-3"
                        >
                            Get Pricing
                        </Link>
                        <div className="text-gray-600 mb-2 mt-3">Description</div>
                        <p className="pb-3">{productData.description}</p>
                        <ul>
                            {productData.materials.map((material, index) => (
                                <List key={index} title={material.title} value={material.value} />
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        </Container>
    );
};
export default ProductDetails;
