import React, { useState } from "react";
import Container from "../components/Container/Container";
import emailjs from "@emailjs/browser";
import arrow from "../assets/arrow-white.png";
import locationIcon from "../assets/location-icon.png";
import emailIcon from "../assets/email-icon.png";
import phoneIcon from "../assets/call-icon.png";
import contactImage from "../assets/contact-image.png";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    phoneNumber: "",
    email: "",
    subject: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_3jv7q4o", "template_3jv7q4o", e.target, "user_3jv7q4o")
      .then(
        (result) => {
          console.log(result.text);
          setFormData({
            name: "",
            company: "",
            phoneNumber: "",
            email: "",
            subject: "",
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="mt-24 lg:mt-32 min-h-svh">
      <img src={contactImage} className="w-full" />
      <Container>
        <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-5">
          <div>
            <div className="w-full pr-10 mb-10">
              <div className="text-[15px] mb-2 text-red-600 font-medium">
                CONTACT US
              </div>
              <h1 className="text-2xl lg:w-1/2 font-bold">
                Get in Touch with Us
              </h1>
            </div>
            {/* Form */}
            <form onSubmit={handleSubmit}>
              <p className="mb-3">Enter your details below.</p>
              <div className="md:grid lg:grid lg:grid-cols-2 gap-2">
                <div>
                  <label htmlFor="name" className="sr-only">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="border focus:outline-[#4c9741] p-2 w-full rounded-md mb-3"
                  />
                </div>
                <div>
                  <label htmlFor="company" className="sr-only">
                    Company
                  </label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    placeholder="Company"
                    value={formData.company}
                    onChange={handleChange}
                    className="border focus:outline-[#4c9741] p-2 w-full rounded-md mb-3"
                  />
                </div>
                <div>
                  <label htmlFor="phoneNumber" className="sr-only">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="border focus:outline-[#4c9741] p-2 w-full rounded-md mb-3"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    className="border focus:outline-[#4c9741] p-2 w-full rounded-md mb-3"
                  />
                </div>
                <div className="col-span-2">
                  <label htmlFor="subject" className="sr-only">
                    Enter Subject
                  </label>
                  <textarea
                    id="subject"
                    name="subject"
                    placeholder="Enter Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="w-full focus:outline-[#4c9741] h-24 p-2 border rounded resize-none"
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                className="mt-4 flex items-center bg-red-600 rounded-full justify-between px-2 text-white py-2"
              >
                Send Message{" "}
                <img className="w-8 ml-3" src={arrow} alt="Arrow icon" />
              </button>
            </form>
          </div>
          <div>
            <div className="lg:p-5 rounded">
              {/* Address */}
              <div className="flex mb-3 bg-gray-100 p-5 rounded">
                <div className="w-[37px] mr-2">
                  <img src={locationIcon} width="23px" alt="Location icon" />
                </div>
                <div>
                  <h2 className="text-xl">Address</h2>
                  <p className="text-base">Industrial Area-13, Sharjah UAE</p>
                </div>
              </div>

              {/* Email */}
              <div className="flex mb-3 bg-gray-100 p-5 rounded">
                <div className="w-[37px] mr-2">
                  <img src={emailIcon} width="23px" alt="Email icon" />
                </div>
                <div>
                  <h2 className="text-xl">Email</h2>
                  <a
                    className="text-base"
                    href="mailto:mails@focusinternationals.com"
                  >
                    mails@focusinternationals.com
                  </a>
                </div>
              </div>

              {/* Phone */}
              <div className="flex mb-3 bg-gray-100 p-5 rounded">
                <div className="w-[37px] mr-2">
                  <img src={phoneIcon} width="23px" alt="Phone icon" />
                </div>
                <div>
                  <h2 className="text-xl">Phone Number</h2>
                  <a className="text-base" href="tel:+97165485423">
                    +971 6 548 5423
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
